<template>
  <div>
    <div class="form row">
      <div class="col-12">
        <validation-provider ref="provider" v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
          <euro-select v-model="form.country" :disabled="readonlyInOrder" :multiselect-props="{
          options: a1ModuleCountries,
          label: 'name',
          'track-by': 'iso',
          searchable: true,
        }" :error-messages="errors" searchable :label="$t('Country')" @input="$refs.provider.validate()">
          </euro-select>
        </validation-provider>
      </div>

      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Certificate kind')" vid="certificate_kind" rules="required">
          <euro-select v-model="form.certificate_kind" :disabled="readonlyInOrder" :multiselect-props="{
          options: a1ModuleCertificateKinds,
          multiple: false,
          label: 'display_name',
          'track-by': 'value',
          searchable: true,
        }" :label="`${$t('Certificate kind')}*`" :error-messages="errors"></euro-select>
        </validation-provider>
      </div>

      <div v-if="a1ModuleCountries && form.certificate_kind == 'ART13'" class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Posting country')" vid="posting_country"
          :rules="{ required: true, 'a1_not_same_as': { otherValue: form.country, otherName: 'Country', certificateKind: form.certificate_kind } }">
          <euro-select v-model="form.posting_country" :disabled="readonlyInOrder" :label="`${$t('Posting country')}*`"
            :error-messages="errors" :multiselect-props="{
          options: a1ModuleCountries,
          'track-by': 'iso',
          label: 'name',
          searchable: true,
          multiple: form.certificate_kind == 'ART12' ? false : true,
        }"></euro-select>
        </validation-provider>
      </div>

      <div v-if="a1ModuleSecondments && form.certificate_kind == 'ART12'" class="col-12 pt-2">
        <div v-if="!readonlyInOrder">
          <span class="card-label">{{ $t("Secondment status filter") }}</span>
          <div class="d-flex align-items-center mb-3">
            <span class="card-label">{{ $t("Active and future") }}</span>
            <euro-checkbox v-model="closedSecondment" size="lg" is-switch class="mx-4"
              @input="getA1ModuleSecondmentsList(closedSecondment)"></euro-checkbox>
            <span class="card-label">{{ $t("Active, future and closed") }}</span>
          </div>
        </div>
        <validation-provider v-slot="{ errors }" :name="$t('Secondment')" vid="secondment" :rules="{ required: true }">
          <euro-select v-model="form.secondment" :disabled="readonlyInOrder" :label="`${$t('Secondment')}*`"
            :error-messages="errors" :multiselect-props="{
          options: a1ModuleSecondments,
          searchable: true,
        }"></euro-select>
        </validation-provider>
      </div>

      <div v-if="form.certificate_kind == 'ART13'" class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="date_from">
          <date-picker v-model="form.date_from" :disabled="readonlyInOrder" :label="`${$t('Valid from')}*`"
            :error-messages="errors"></date-picker>
        </validation-provider>
      </div>

      <div v-if="form.certificate_kind == 'ART13'" class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="date_to">
          <date-picker v-model="form.date_to" :disabled="readonlyInOrder" :label="`${$t('Valid to')}*`"
            :overrides="validToOverrides" :error-messages="errors"></date-picker>
        </validation-provider>
      </div>

      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors" :disabled="disabledTus"
              accept="application/pdf" :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
              @change="tusId => {
          form.attachment_tus = tusId;
        }
          "></euro-tus-file-input>
            <b-tooltip v-if="disabledTus" target="attachmentFile">
              {{ $t("Enter the above required data first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import icons from "@/core/config/icons";
import { mapState, mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import { getA1CertificateKinds } from "@/core/abstractions/physicalPerson";
import SecondmentEmployeeService from "@/core/services/secondment/secondment-employee.service";
import { backendErrorSwal } from "@/core/helpers/swal";

export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    physicalPerson: {
      type: Object,
      default: () => null,
    },
    readonlyInOrder: {
      type: Boolean,
      default: false,
    },
    secondment: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      icons,
      administrativeStructures: [],
      form: {
        country: "",
        posting_country: [],
        date_from: null,
        date_to: null,
        certificate_kind: "ART12",
        counterpart: null,
        attachment_tus: null,
        secondment: null
      },
      a1ModuleCountries: [],
      a1ModuleCertificateKinds: [],
      a1ModuleSecondments: [],
      secondmentCountryList: [],
      secondmentDates: [],
      closedSecondment: true,
      disabledTus: true,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("constants", ["countriesMap", "moduleA1Group"]),
    ...mapGetters("user", ["getUserCountryOfEstablishment", "date_format"]),

    administrativeStructuresByCountry() {
      if (!this.form.country) return this.administrativeStructures;
      return this.administrativeStructures.filter(as => as.country_of_establishment == this.form.country);
    },

    validToOverrides() {
      if (!this.form.date_from || this.form.date_from == "Invalid date") return null;

      return {
        maxDate: DateService.addYears(2, this.form.date_from),
        minDate: this.form.date_from,
      };
    },
  },

  watch: {
    'form': {
      deep: true,
      handler(val) {
        const out = { ...val }
        if (this.form.certificate_kind == "ART13") {
          out.secondment = null
        }
        if (this.form.certificate_kind == "ART12" && this.form.secondment && !this.readonlyInOrder) {
          out.posting_country = [this.secondmentCountryList[this.form.secondment]];
          out.date_from = this.secondmentDates[this.form.secondment]?.date_from
          out.date_to = this.secondmentDates[this.form.secondment]?.date_to
        }
        if (this.readonlyInOrder) {
          out.posting_country = [this.secondment.country]
          out.date_from = this.secondment.date_start
          out.date_to = this.secondment.date_end
        }
        if (this.form.certificate_kind) {
          this.disabledTus = false
        }
        this.$emit("input", out);
      }
    },
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };

      // Check max date_to is after 2 years
      const maxDate = DateService.addYears(2, this.form.date_from);
      if (DateService.diff(this.form.date_to, maxDate) > 0) {
        this.form.date_to = maxDate;
      }
      await this.$nextTick();
    }

    this.form.country = this.getUserCountryOfEstablishment;
    getA1CertificateKinds().then(res => (this.a1ModuleCertificateKinds = res));
    this.moduleA1Group.forEach(element => {
      this.a1ModuleCountries.push(this.countriesMap[element.country]);
    });
    this.a1ModuleCountries.sort((a, b) => a.name.localeCompare(b.name));
    if (this.readonlyInOrder) {
      this.form.secondment = this.secondment.id
      this.a1ModuleSecondments.push({
        value: this.secondment.id,
        text: `${this.secondment.country.toUpperCase()} - ${this.$t('From')} "${DateService.format(this.secondment.date_start, this.date_format)}" ${this.$t('To')} "${DateService.format(this.secondment.date_end, this.date_format)}"`
      })
    }
    else {
      await this.getA1ModuleSecondmentsList(this.closedSecondment);
    }
  },
  methods: {
    async getA1ModuleSecondmentsList(secondmentsOpen) {
      this.a1ModuleSecondments = [];
      // this.form.secondment = null;
      await SecondmentEmployeeService.getEmployeeSecondment({
        perPage: 150,
        page: 1,
        employeeId: this.physicalPerson.employee_id,
        expand: 'secondment_detail',
        a1Countries: true,
        adminView: true,
        secondmentOpen: !secondmentsOpen,
      }).then(response => {
        response.data.results.forEach(secondment => {
          this.secondmentCountryList[secondment.secondment] = secondment.secondment_detail.country
          this.a1ModuleSecondments.push({
            value: secondment.secondment,
            text: `${this.countriesMap[secondment.secondment_detail.country].name.toUpperCase()} - ${this.$t('From')} "${DateService.format(secondment.secondment_detail.date_start, this.date_format)}" ${this.$t('To')} "${DateService.format(secondment.secondment_detail.date_end, this.date_format)}"`
          })
          this.secondmentDates[secondment.secondment] = {
            date_from: secondment.secondment_detail.date_start,
            date_to: secondment.secondment_detail.date_end,
          }
        })
      }).catch(err => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },
  }
};
</script>
