import api from "../api.service";
import AvailabilityService from "@/core/services/availability.service";
import {
  generateRandomColor
} from "@/core/helpers/colors";

export default {
  get PATH() {
    return "secondment-employee";
  },
  
  async getEmployeeSecondment({
    employeeId,
    perPage,
    page,
    sortBy = null,
    sortDesc = null,
    expand = null,
    a1Countries = false,
    secondmentOpen = false,
    adminView = null,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      employee_ids: employeeId,
      limit: perPage,
      offset,
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    if (a1Countries) {
      params.a1_countries = a1Countries
    }
    if(secondmentOpen){
      params.secondments_open = secondmentOpen
    }
    if(adminView){
      params.admin_view = adminView
    }
    return await api.get(`${this.PATH}/`, {
      params,
    });
  },

  async employeeAvailability(employeeId, dateFrom, dateTo) {
    const res = await api.post(`${this.PATH}/get_employee_availability/`, {
      employee: employeeId,
      date_start: dateFrom,
      date_end: dateTo,
    });
    return res.data;
  },

  async employeesAvailabilityCountry(dateFrom, dateTo, countryIso) {
    const r = await api.post(`${this.PATH}/get_company_employees_availability/`, {
      date_start: dateFrom,
      date_end: dateTo,
      secondment_country: countryIso
    });
    return r.data;
  },

  async employeesSalary(country) {
    const r = await api.post(`${this.PATH}/get_company_employees_salary_adjustment/`, {
      country
    });
    return r.data;
  },

  async secondmentEmployeeGanttData({
    secondmentEmployee,
    secondmentId,
    secondmentDateStart,
    secondmentDateEnd
  }) {
    const ganttData = [];

    const r = await this.employeeAvailability(
      secondmentEmployee.employee,
      secondmentEmployee.date_start,
      secondmentEmployee.date_end,
    );
    const {
      busy_dates,
      status
    } = r;

    var employeeColor = "#e3e3e3";
    try {
      employeeColor = AvailabilityService.enumToColor(AvailabilityService.apiStatusToAvailabilityEnum(status));
    } catch (err) {
      // If there's any error we take the default color.
      employeeColor = AvailabilityService.enumToColor();
    }

    const employeeData = {
      label: `${secondmentEmployee.name} (current)`,
      dateStart: secondmentEmployee.date_start,
      dateEnd: secondmentEmployee.date_end,
      color: employeeColor,
    };
    const secondmentData = {
      label: "Secondment (current)",
      dateStart: secondmentDateStart,
      dateEnd: secondmentDateEnd,
      color: "#03f4fc",
    };

    ganttData.push(employeeData, secondmentData);

    busy_dates.forEach(x => {
      if (secondmentId && secondmentId === x.secondment) {
        return;
      }
      const rgb = generateRandomColor();
      ganttData.push({
        label: x.secondment_name,
        dateStart: x.date_start,
        dateEnd: x.date_end,
        color: `rgb(${rgb.r},${rgb.g},${rgb.b})`,
      });
    });

    return ganttData;
  },
};