<template>
  <validation-observer :ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal :id="id" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Edit A1 certificate") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create A1 certificate") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">{{ $t('Cancel') }}</b-button>
          </div>
        </div>
      </template>

      <physical-person-a1-module-form ref="physicalPersonA1Form" :physical-person="physicalPerson"
        :existing-form="existingForm" :readonly-in-order="readonlyInOrder" :secondment="secondment"
        @input="updateItem"></physical-person-a1-module-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import PhysicalPersonA1ModuleForm from "@/view/components/forms/physical-person/PhysicalPersonA1ModuleForm.vue";
import { mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from "@/core/helpers";
import PhysicalPersonA1ModuleService from "@/core/services/physical-person/physical-person-a1-module.service";
import icons from "@/core/config/icons"

export default {
  components: {
    PhysicalPersonA1ModuleForm,
  },

  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
    existingForm: {
      type: Object,
      default: () => true,
    },
    id: {
      type: String,
      default: "create-a-module",
    },
    readonlyInOrder: {
      type: Boolean,
      default: false,
    },
    secondment: {
      type: Object,
      default: () => ({}),
    },
    currentItemId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      item: null,
      isSaving: false,
      apiErrors: null,
      icons
    };
  },

  computed: {
    ...mapGetters("constants", ["countriesMap"]),

    validationObserver() {
      return `validationObserver-${this._uid}`;
    },

    isEdit() {
      return Object.keys(this.existingForm).length > 0
    }
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs[this.validationObserver];
        const newErrors = {};
        for (const key in val) {
          if (this.item.certificate_kind == "ART12" && (key == "date_to" || key == "date_from")) {
            newErrors["secondment"] = val[key][0];
          }
          else {
            newErrors[key] = val[key][0];
          }
        }
        formObs.setErrors(newErrors);
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },

    onModalHidden() {
      this.item = null;
      this.isSaving = false;
    },

    async submit() {
      this.isSaving = true;
      try {
        if (this.item.id) {
          await PhysicalPersonA1ModuleService.update(this.item, this.item.id);
        } else {
          await PhysicalPersonA1ModuleService.create({ ...this.item, persona: this.physicalPerson.id });
        }
        if (this.currentItemId != "") {
          this.$emit("update-status", { newStatus: 'approve', id: this.currentItemId });
        }
        this.$emit("refresh");
        this.closeModal();
        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        successToast(title);
      } catch (err) {
        let message = this.$t("Failed to create the document. Please try again.");
        if (this.isEdit) {
          message = this.$t("Failed to update the document. Please try again.");
        }
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else if (err.response.data.detail) {
          message = err.response.data.detail;
        }
        await backendErrorSwal(err, message);
      } finally {
        this.isSaving = false;
      }
    },
    updateItem(value) {
      this.item = value;
    }
  },
};
</script>
