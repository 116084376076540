var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    ref: "provider",
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "disabled": _vm.readonlyInOrder,
            "multiselect-props": {
              options: _vm.a1ModuleCountries,
              label: 'name',
              'track-by': 'iso',
              searchable: true
            },
            "error-messages": errors,
            "searchable": "",
            "label": _vm.$t('Country')
          },
          on: {
            "input": function input($event) {
              return _vm.$refs.provider.validate();
            }
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Certificate kind'),
      "vid": "certificate_kind",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "disabled": _vm.readonlyInOrder,
            "multiselect-props": {
              options: _vm.a1ModuleCertificateKinds,
              multiple: false,
              label: 'display_name',
              'track-by': 'value',
              searchable: true
            },
            "label": "".concat(_vm.$t('Certificate kind'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.certificate_kind,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "certificate_kind", $$v);
            },
            expression: "form.certificate_kind"
          }
        })];
      }
    }])
  })], 1), _vm.a1ModuleCountries && _vm.form.certificate_kind == 'ART13' ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Posting country'),
      "vid": "posting_country",
      "rules": {
        required: true,
        'a1_not_same_as': {
          otherValue: _vm.form.country,
          otherName: 'Country',
          certificateKind: _vm.form.certificate_kind
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-select', {
          attrs: {
            "disabled": _vm.readonlyInOrder,
            "label": "".concat(_vm.$t('Posting country'), "*"),
            "error-messages": errors,
            "multiselect-props": {
              options: _vm.a1ModuleCountries,
              'track-by': 'iso',
              label: 'name',
              searchable: true,
              multiple: _vm.form.certificate_kind == 'ART12' ? false : true
            }
          },
          model: {
            value: _vm.form.posting_country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "posting_country", $$v);
            },
            expression: "form.posting_country"
          }
        })];
      }
    }], null, false, 2487576176)
  })], 1) : _vm._e(), _vm.a1ModuleSecondments && _vm.form.certificate_kind == 'ART12' ? _c('div', {
    staticClass: "col-12 pt-2"
  }, [!_vm.readonlyInOrder ? _c('div', [_c('span', {
    staticClass: "card-label"
  }, [_vm._v(_vm._s(_vm.$t("Secondment status filter")))]), _c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_c('span', {
    staticClass: "card-label"
  }, [_vm._v(_vm._s(_vm.$t("Active and future")))]), _c('euro-checkbox', {
    staticClass: "mx-4",
    attrs: {
      "size": "lg",
      "is-switch": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getA1ModuleSecondmentsList(_vm.closedSecondment);
      }
    },
    model: {
      value: _vm.closedSecondment,
      callback: function callback($$v) {
        _vm.closedSecondment = $$v;
      },
      expression: "closedSecondment"
    }
  }), _c('span', {
    staticClass: "card-label"
  }, [_vm._v(_vm._s(_vm.$t("Active, future and closed")))])], 1)]) : _vm._e(), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Secondment'),
      "vid": "secondment",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-select', {
          attrs: {
            "disabled": _vm.readonlyInOrder,
            "label": "".concat(_vm.$t('Secondment'), "*"),
            "error-messages": errors,
            "multiselect-props": {
              options: _vm.a1ModuleSecondments,
              searchable: true
            }
          },
          model: {
            value: _vm.form.secondment,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "secondment", $$v);
            },
            expression: "form.secondment"
          }
        })];
      }
    }], null, false, 2675613249)
  })], 1) : _vm._e(), _vm.form.certificate_kind == 'ART13' ? _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid from'),
      "vid": "date_from"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('date-picker', {
          attrs: {
            "disabled": _vm.readonlyInOrder,
            "label": "".concat(_vm.$t('Valid from'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.date_from,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_from", $$v);
            },
            expression: "form.date_from"
          }
        })];
      }
    }], null, false, 3099802826)
  })], 1) : _vm._e(), _vm.form.certificate_kind == 'ART13' ? _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid to'),
      "vid": "date_to"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('date-picker', {
          attrs: {
            "disabled": _vm.readonlyInOrder,
            "label": "".concat(_vm.$t('Valid to'), "*"),
            "overrides": _vm.validToOverrides,
            "error-messages": errors
          },
          model: {
            value: _vm.form.date_to,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_to", $$v);
            },
            expression: "form.date_to"
          }
        })];
      }
    }], null, false, 3928496529)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "disabled": _vm.disabledTus,
            "accept": "application/pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), _vm.disabledTus ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }