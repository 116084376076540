const AvailabilityEnum = {
  NOT_AVAILABLE: 0,
  AVAILABLE: 1,
  PARTIAL: 2,
};
Object.freeze(AvailabilityEnum);
export default {
  Enum: AvailabilityEnum,

  enumToString(availabilityEnum) {
    switch (availabilityEnum) {
      case this.Enum.NOT_AVAILABLE:
        return "Not available";
      case this.Enum.AVAILABLE:
        return "Available";
      case this.Enum.PARTIAL:
        return "Partially available";
      default:
        return "";
      // throw new Error("Wrong availability");
    }
  },

  // Sometimes the api returns the availability as these strings.
  apiStatusToAvailabilityEnum(status) {
    switch (status) {
      case "ok":
        return this.Enum.AVAILABLE;
      case "not_available":
        return this.Enum.NOT_AVAILABLE;
      case "warning":
        return this.Enum.PARTIAL;
      default:
        throw new Error("availability not found");
    }
  },

  enumToColor(availabilityEnum) {
    switch (availabilityEnum) {
      case this.Enum.NOT_AVAILABLE:
        return "#eb3434";
      case this.Enum.AVAILABLE:
        return "#77eb34";
      case this.Enum.PARTIAL:
        return "#ebd334";
      default:
        return "#9e9999";
    }
  },

  getAvailabilityEnum(employee) {
    if (employee) {
      if (employee.hasOwnProperty("is_available") && employee.is_available) {
        return this.Enum.AVAILABLE;
      }
      if (
        employee.hasOwnProperty("partial_availability") &&
        employee.partial_availability
      ) {
        return this.Enum.PARTIAL;
      }
    }
    return this.Enum.NOT_AVAILABLE;
  },

  checkAvailability(date_start, date_end, busy_dates) {
    let partialAvailability = false;
    // Iterate through the elements of the period array
    for (let i = 0; i < busy_dates.length; i++) {
      const busy_date = busy_dates[i];

      // Check if there is overlap between periods
      if (
        date_start <= busy_date.date_end &&
        date_end >= busy_date.date_start
      ) {
        // Overlap found

        // Check whether the period is fully occupied or partially occupied
        if (
          date_start >= busy_date.date_start &&
          date_end <= busy_date.date_end
        ) {
          // Fully occupied period
          return this.Enum.NOT_AVAILABLE;
        } else if (
          date_start < busy_date.date_start &&
          date_end > busy_date.date_end
        ) {
          // Partially occupied period
          partialAvailability = true;
        } else {
          // Otherwise it checks whether the period is busy at the beginning or at the end
          if (date_start < busy_date.date_start) {
            // Busy at first
            partialAvailability = true;
          } else {
            // Busy at last
            partialAvailability = true;
          }
        }
      }
    }
    return partialAvailability == true
      ? this.Enum.PARTIAL
      : this.Enum.AVAILABLE;
  },

  checkOverlappingSecondments(secondmentDateStart, secondmentDateEnd, busy_dates) {
    let overlappingSecondments = [];

    if (busy_dates.length > 0) {
      busy_dates.forEach((secondmentToCheck) => {
        // case in which the end date of the current secondment to be checked is contained in the range of the main secondment
        if(secondmentToCheck.date_end <= secondmentDateEnd && secondmentToCheck.date_end >= secondmentDateStart){
          let start=secondmentDateStart;
          // update the dates that exceed the main secondment range for correct display in the gantt
          if(secondmentToCheck.date_start > secondmentDateStart){
            start=secondmentToCheck.date_start;
          }

          overlappingSecondments.push({
            date_end:secondmentToCheck.date_end,
            date_start:start,
            secondment:secondmentToCheck.secondment,
            secondment_name:secondmentToCheck.secondment_name,
          });
        }
        // case in which the start date of the current secondment to be checked is contained in the range of the main secondment
        else if(secondmentToCheck.date_start <= secondmentDateEnd && secondmentToCheck.date_start >= secondmentDateStart && !overlappingSecondments.find(el => (el.secondment == secondmentToCheck.secondment))){
          let end=secondmentDateEnd;
          // update the dates that exceed the main secondment range for correct display in the gantt
          if(secondmentToCheck.date_end < secondmentDateEnd){
            end=secondmentToCheck.date_end;
          }

          overlappingSecondments.push({
            date_end:end,
            date_start:secondmentToCheck.date_start,
            secondment:secondmentToCheck.secondment,
            secondment_name:secondmentToCheck.secondment_name,
          });
        }
        // case in which the dates of the current secondment to be checked contain the range of the main secondment
        else if(secondmentToCheck.date_start < secondmentDateStart && secondmentToCheck.date_end > secondmentDateEnd){
          overlappingSecondments.push({
            date_end:secondmentDateEnd,
            date_start:secondmentDateStart,
            secondment:secondmentToCheck.secondment,
            secondment_name:secondmentToCheck.secondment_name,
          });
        }
      });
    }

    return overlappingSecondments;
  },
};
